import React, { useCallback, useMemo, useState } from 'react';

import Layout from '../components/layout';
import SEO from "../components/seo";
import GoogleMap from "../components/pageblocks/googleMap";
import { ArticleModel } from '../servermodels/articleModel';
import { PageBlockModel, PageBlockRichText, PageBlockTwoColumnsRichText, PageBlockImages } from '../servermodels/pageBlockModels';
import HtmlContent from '../components/htmlcontent';
import { Gallery, GalleryPhoto } from '../components/gallery';


type Props = {
    pageContext: ArticleModel
};

function renderPageBlock(block: PageBlockModel, showGallery: (imgIndex: number) => void): React.ReactNode {

    switch (block.strapi_component) {
        case 'page-blocks.google-map': {
            return <GoogleMap />;
        }
        case 'page-blocks.rich-text': {
            const b = block as PageBlockRichText;
            return <>
                <HtmlContent htmlContent={b.Content?.data?.Content} className="wys-content" />
                <div className="clear" />
            </>;
        }
        case 'page-blocks.two-columns-rich-text': {
            const b = block as PageBlockTwoColumnsRichText;
            return <div className="row page-content-row">
                <div className="col">
                    <HtmlContent htmlContent={b.ContentLeft?.data?.ContentLeft} className="wys-content" />
                </div>
                <div className="col">
                    <HtmlContent htmlContent={b.ContentRight?.data?.ContentRight} className="wys-content" />
                </div>
            </div>;
        }
        case 'page-blocks.images': {
            const b = block as PageBlockImages;

            if (!b.Images) {
                return null;
            }

            return (
                <div className="product-detail--section-imgs">
                    {b.Images.map((img, i) => {
                        return (
                            <img key={i} src={img.localFile.publicURL} alt={img.alternativeText} className={`inner-image ${img.height > img.width ? 'portrait' : 'landscape'}`} onClick={() => showGallery(i)} />
                        )
                    })}
                </div>
            )
        }
        default:
            throw new Error('Unknown strapi component. Component: ' + block.strapi_component);
    }

}

const PageTemplate: React.FC<Props> = (props) => {
    const page = props.pageContext;

    const [showGallery, setShowGallery] = useState(false);
    const [galleryIndex, setGalleryIndex] = useState(0);
    
    const handleHide = useCallback(() => { setShowGallery(false) }, [false]);
    const handleShow = useCallback((imgIndex: number) => {
        setShowGallery(true);
        setGalleryIndex(imgIndex);
    }, [true]);
    const galleryData = useMemo(() => createGalleryData(props.pageContext.PageBlock), [props.pageContext.PageBlock]);

    return (
        <Layout currentPageTitle={page.Title} contentIsPage>
            <SEO title={props.pageContext.Title} description={page.MetaDescription} keywords={page.MetaKeywords} />
            <Gallery show={showGallery} photos={galleryData} onClose={handleHide} startIndex={galleryIndex} />
            <div className="container page">
                <h1 className="main-title page">
                    <span>{page.Title}</span>
                </h1>
                {page.PageBlock ?
                    page.PageBlock.map((block, i) => (
                        <React.Fragment key={i}>
                            {renderPageBlock(block, handleShow)}
                        </React.Fragment>
                    ))
                : null}
            </div>
        </Layout>
    );
};

function createGalleryData(pageBlocks: PageBlockModel[]): GalleryPhoto[] {
    if (!pageBlocks) {
        return []
    }

    const result: GalleryPhoto[] = [];

    pageBlocks.forEach(p => {
        if (p.strapi_component !== 'page-blocks.images') {
            return;
        }

        const b = p as PageBlockImages;
        if (!b.Images) {
            return;
        }

        b.Images.forEach(i => {
            result.push({
                original: i.localFile.publicURL,
                thumbnail: i.localFile.publicURL,
                originalTitle: i.caption,
                description: i.caption,
            });
        });
    });

    return result;
}

export default PageTemplate;