import React from 'react';

const GoogleMap: React.FC = () => {

    return <div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2608.309551771174!2d16.6867986!3d49.17571239999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4712eb20cb38208d%3A0xcac77582bd101274!2sTu%C5%99anka+221%2F17%2C+627+00+Brno-Brno-Slatina!5e0!3m2!1scs!2scz!4v1420406970341"
				        style={{ width: '100%', height: '400px', border:0}}></iframe>
    </div>
}

export default GoogleMap;